var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "width": "".concat(_vm.width, "px"),
      "height": "".concat(_vm.height, "px"),
      "viewBox": "0 0 14 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2.51386 15.0834H1.56942C1.38156 15.0834 1.20139 15.0088 1.06855 14.8759C0.935712 14.7431 0.861084 14.5629 0.861084 14.3751V10.1251C0.861084 9.93722 0.935712 9.75705 1.06855 9.62421C1.20139 9.49138 1.38156 9.41675 1.56942 9.41675H2.51386C2.70172 9.41675 2.88189 9.49138 3.01473 9.62421C3.14757 9.75705 3.2222 9.93722 3.2222 10.1251V14.3751C3.2222 14.5629 3.14757 14.7431 3.01473 14.8759C2.88189 15.0088 2.70172 15.0834 2.51386 15.0834ZM9.12497 15.0834H8.18053C7.99267 15.0834 7.8125 15.0088 7.67966 14.8759C7.54682 14.7431 7.47219 14.5629 7.47219 14.3751V7.29175C7.47219 7.10389 7.54682 6.92372 7.67966 6.79088C7.8125 6.65804 7.99267 6.58341 8.18053 6.58341H9.12497C9.31283 6.58341 9.493 6.65804 9.62584 6.79088C9.75868 6.92372 9.83331 7.10389 9.83331 7.29175V14.3751C9.83331 14.5629 9.75868 14.7431 9.62584 14.8759C9.493 15.0088 9.31283 15.0834 9.12497 15.0834ZM12.4305 15.0834H11.4861C11.2982 15.0834 11.1181 15.0088 10.9852 14.8759C10.8524 14.7431 10.7778 14.5629 10.7778 14.3751V3.98619C10.7778 3.79833 10.8524 3.61816 10.9852 3.48533C11.1181 3.35249 11.2982 3.27786 11.4861 3.27786H12.4305C12.6184 3.27786 12.7986 3.35249 12.9314 3.48533C13.0642 3.61816 13.1389 3.79833 13.1389 3.98619V14.3751C13.1389 14.5629 13.0642 14.7431 12.9314 14.8759C12.7986 15.0088 12.6184 15.0834 12.4305 15.0834ZM5.81942 15.0834H4.87497C4.68711 15.0834 4.50694 15.0088 4.37411 14.8759C4.24127 14.7431 4.16664 14.5629 4.16664 14.3751V1.62508C4.16664 1.43722 4.24127 1.25705 4.37411 1.12421C4.50694 0.991376 4.68711 0.916748 4.87497 0.916748H5.81942C6.00728 0.916748 6.18745 0.991376 6.32028 1.12421C6.45312 1.25705 6.52775 1.43722 6.52775 1.62508V14.3751C6.52775 14.5629 6.45312 14.7431 6.32028 14.8759C6.18745 15.0088 6.00728 15.0834 5.81942 15.0834Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }